<template>
    <div class="carousel">
        <div
            class="carousel-wrapper"
            @pointerdown="onDragStart($event)"
        >
            <div
                ref="observable"
                :style="{
                    '--slide-width': `${slideWidth}px`,
                    width: view === 'slide' ? `${slideWidth}px` : null,
                    transform: transform ? `translateX(${currentOffset}px)` : null,
                    left: transform ? null : `${currentOffset}px`
                }"
                :class="[ center, { dragging: isDragging, snapping: isSnapping } ]"
                class="carousel-inner"
            >
                <slot v-if="infinite" />
                <slot />
                <slot v-if="infinite" />
            </div>
        </div>
        <slot name="slide-navigation" :direction="{ canGoForward, canGoBackward }" />
        <template v-if="navigation">
            <Btn
                variant="create"
                class="left navigation"
                icon="chevron-left"
                colour="white"
                :disabled="!canGoBackward"
                :icon-label="$trans('previous')"
                @click="goToPreviousSlide"
            />
            <Btn
                variant="create"
                class="right navigation"
                icon="chevron-right"
                colour="white"
                :icon-label="$trans('next')"
                :disabled="!canGoForward"
                @click="goToNextSlide"
            />
        </template>
    </div>
</template>

<script>
import { useResponsive, getElement } from '@/store/responsive';

import Btn from '@/components/utility/button';

export default {
    name: 'Carousel',
    components: { Btn },
    props: {
        length: { type: Number, default: 0 },
        slideWidth: { type: Number, default: 320 },
        view: { type: String, default: 'slide' }, // [ slide, full ]

        disabled: { type: Boolean, default: false },
        navigation: { type: Boolean, default: false },
        center: { type: Boolean, default: true },
        infinite: { type: Boolean, default: false },

        // Allow focusing to new slides to automatically navigate carousel, this will mess up certain types of carousels so it is off by default
        focusNavigable: { type: Boolean, default: false },
        transform: { type: Boolean, default: true },
    },
    emits: ['visible-slides', 'select'],
    slots: ['default', 'slide-navigation'],
    data() {
        return {
            currentSlide: 0,
            dragOffset: 0,
            dragStartX: 0,
            dragStartY: 0,
            offset: 0,
            timer: null,

            isDragging: false,
            isSnapping: false
        };
    },
    computed: {
        component() {
            return getElement(this);
        },
        fullyVisibleSlides() {
            if (this.view === 'slide') return 1;
            return Math.floor(this.component.width / this.slideWidth);
        },
        currentOffset() {
            return (this.offset + this.dragOffset) * -1;
        },
        maxOffset() {
            if (this.infinite) return Infinity;
            return this.slideWidth * (this.length - 1);
        },
        canGoForward() {
            if (this.infinite) return true;
            return this.currentSlide < (this.length - 1);
        },
        canGoBackward() {
            if (this.infinite) return true;
            return this.currentSlide > 0;
        },
        nextSlide() {
            if (this.canGoForward) return this.currentSlide + 1;
            return this.currentSlide;
        },
        previousSlide() {
            if (this.canGoBackward) return this.currentSlide - 1;
            return this.currentSlide;
        }
    },
    watch: {
        slideWidth(to) {
            this.offset = Math.max(Math.min(to * this.currentSlide, this.maxOffset), 0);
        },
        fullyVisibleSlides(to) {
            this.$emit('visible-slides', to);
        }
    },
    mounted() {
        useResponsive(this, this.$refs.observable);
        document.addEventListener('focusin', this.handleFocus);
        
        if (this.infinite) {
            this.$nextTick(() => {
                this.offset = this.slideWidth * this.length;
                this.currentSlide = 0;
            });
        }
    },
    beforeUnmount() {
        useResponsive.removeElement(this);
        document.removeEventListener('focusin', this.handleFocus);
    },
    methods: {
        handleFocus(event) {
            if (!this.focusNavigable) return;
            this.$el.scrollLeft = 0;
            Array.from(this.$refs.observable.children).forEach((slide, index) => {
                if (slide.contains(event.target) && index !== this.currentSlide) {
                    this.goToSlide(index);
                }
            });
        },
        goToPreviousSlide() {
            if (this.canGoBackward) this.goToSlide(this.previousSlide);
        },
        goToNextSlide() {
            if (this.canGoForward) this.goToSlide(this.nextSlide);
        },
        goToSlide(slide) {
            this.offset = Math.max(Math.min(this.slideWidth * slide, this.maxOffset), 0);

            if (this.infinite) {
                this.currentSlide = slide % this.length;
                if (this.currentSlide === 0 || this.currentSlide === this.length - 1) {
                    this.$timeout(() => {
                        this.isSnapping = true;
                        this.$nextTick(() => this.offset = Math.max(Math.min(this.slideWidth * this.currentSlide, this.maxOffset), 0));
                        this.$timeout(() => this.isSnapping = false, 500);
                    }, 800);
                }
            } else {
                this.currentSlide = Math.max(Math.min(slide, this.length - 1), 0);
            }
            this.$emit('select', this.currentSlide);
            this.$el.scrollLeft = 0;
        },
        isFullyVisible(slide) {
            return slide >= this.currentSlide && slide < this.fullyVisibleSlides + this.currentSlide;
        },
        onDragStart(e) {
            if (this.disabled || this.disableDrag) return;

            document.addEventListener('pointerup', this.onDragEnd);
            document.addEventListener('pointermove', this.onDrag);

            this.isDragging = true;
            this.dragStartX = e.clientX;
            this.dragStartY = e.clientY;
        },
        onDrag(e) {
            const deltaX = this.dragStartX - e.clientX;
            this.dragOffset = deltaX;

            // Cancel the drag on a debounce for touch devices —— if the pointerup happens offscreen we can get caught halfway through a drag
            if (this.$browser.is.touch) this.timer = this.$debounce(() => this.onDragEnd(), 350, this.timer);
        },
        onDragEnd() {
            this.offset += this.dragOffset;
            this.dragOffset = 0;

            this.goToSlide(Math.round(this.offset / this.slideWidth));

            document.removeEventListener('pointerup', this.onDragEnd);
            document.removeEventListener('pointermove', this.onDrag);
            this.isDragging = false;
        }
    }
};
</script>

<style lang="less">

.carousel {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    touch-action: pan-y;

    .carousel-wrapper {
        width: 100%;
        touch-action: pan-y;
        overflow: visible;
        .carousel-inner {
            display: flex;
            overflow: visible;
            padding: 24px 0;
            transition: transform 0.35s var(--curve), left 0.35s var(--curve);
            perspective: none;
            position: relative;
            will-change: transform, left;
            &.center { margin: 0 auto; }
            &.dragging, &.snapping { transition: none; }
            > * {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: var(--slide-width);
                max-width: var(--slide-width);
                user-select: none;
            }
        }
    }
}
.cobutton.navigation.create {
    position: absolute;
    &.left { left: 8px; }
    &.right { right: 8px; }
}
</style>
